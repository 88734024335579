import { useState } from "react";
import React from "react";
import CopyrightText from "./CopyrightText";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CustomButton from "./common/Button";
import { Link } from "react-router-dom";
import { Input } from "./common/Input";
import TextArea from "./common/TextArea";
import Validator from "validatorjs";
import axiosInstance from "../helpers/axios";
import Telephone from "./common/Telephone";
import FooterAccordion from "./FooterAccordion";

const useStyles = makeStyles(() => ({
  root: {
    "& .Mui-expanded": {
      backgroundColor: "rgba(66, 91, 131, 0.7) !important",
      padding: "0.5rem !important",
    },
    backgroundColor: "transparent !important",
    // paddingY: '30px',
    // '&:before': {
    //   backgroundColor: 'transparent',
    // },
  },
  details: {
    margin: "0 !important",
    backgroundColor: "transparent",
    borderRadius: "0.5rem !important",
  },

  summary: {
    // pointerEvents: 'none',
    "& .Mui-expanded": {
      backgroundColor: "transparent !important",
    },
    backgroundColor: "transparent !important",

    borderRadius: "9px !important",
    margin: "0px",
    padding: "0px",
    maxHeight: "25px",
  },
  icon: {
    pointerEvents: "auto",
  },
}));
const Footer = ({ formRef, viewreport, bepartner }) => {
  const fields = {
    name: "",
    phone: "",
    email: "",
    notes: "",
    nationality: "",
  };

  const rules = {
    name: "required|max:20",
    phone: "required",
    email: "required|email",
    notes: "required",
  };

  const [params, setParams] = useState(fields);
  const [errors, setErrors] = useState(fields);

  const handleChange = (e) => {

    if (e.target) {
      const { name, value } = e.target;
      updateParams([{ name, value }]);
    }
    if (e.url) {
      updateParams([{ name: e?.name, value: e?.url }]);
    }
    const reset = {};
    setErrors(reset);
  };
  const width = typeof window !== "undefined" ? window?.innerWidth : null;
  const buttonHeight = width <= 768 ? "47px" : "56px";

  const updateParams = (records) => {

    const newParams = JSON.parse(JSON.stringify(params));
    // console.log('newParams:', newParams)

    Object.keys(records).forEach(
      (key) => (newParams[records[key].name] = records[key].value)
    );

    // console.log('newParams:', newParams)
    setParams(newParams);
  };

  const validate = (parameters, rule) => {
    const validator = new Validator(parameters, rule);

    if (validator.fails()) {
      const fieldErrors = {};

      /* eslint-disable */
      for (const key in validator.errors.errors) {
        fieldErrors[key] = validator.errors.errors[key][0];
      }
      /* eslint-enable */

      setErrors(fieldErrors);
      console.log("fieldErrors:", fieldErrors);

      return false;
    }
    const reset = {};
    setErrors(reset);
    return true;
  };

  const handleSubmit = () => {

    if (!validate(params, rules)) {
      return;
    }
    handlePartnerUs();
  };

  const [message, setMessage] = useState("");

  const handlePartnerUs = () => {
    axiosInstance
      .post("/partner-us", params)
      .then((res) => {
        // console.log(res.data.data.message)
        let data = res.data.data.message;
        setMessage(data);
      })
      .catch((err) => {
        console.log("error:", err);
      });

    setParams({ name: "", email: "", notes: "", phone: "", nationality: "" });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleAccordian = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [isValid, setIsValid] = useState(false);
  const checkValid = (value) => {
    setIsValid(value);
  };

  const onPhoneNumberChange = (
    isValid,
    value,
    selectedCountryData,
    fullNumber,
    extension
  ) => {
    // console.log('', {
    //   value: value,
    //   isValid: isValid,
    //   fullNumber: fullNumber,
    //   selectedCountryData: selectedCountryData
    // })
    setParams({
      ...params,
      phone: fullNumber,
      nationality: selectedCountryData?.name,
    });
    if (!isValid) {
      setErrors({
        ...errors,
        phone: "Enter valid phone number",
        nationality: selectedCountryData.name,
      });
    } else {
      setErrors({ ...errors, phone: "" });
    }
  };

  return (
    <div className="">
      {/*1. View report */}

      {bepartner && (
        <div ref={formRef} className="mb-72 mt-20 sm:mb-48  md:mt-20 lg:mt-44">
          <p className="pb-1 text-center font-satoshiMedium text-BlueJay opacity-50 md:text-base lg:text-[22px] ">
            For Partner{" "}
          </p>
          <p className="text-center font-jostSemibold text-2xl font-semibold  text-DarkGrey sm:font-bold lg:text-4xl">
            Get in touch by writing to us
          </p>
        </div>
      )}

      {/* 2.desktop footer data */}
      <div
        className={` relative ${bepartner ? " md:pt-44 lg:pt-80" : ""}  ${
          viewreport ? "mt-80 " : ""
        }  radial-gradient w-full`}
      >
        {viewreport && (
          <div className=" z-60 m-auto max-w-[343px] sm:max-w-[700px] lg:max-w-[1030px]">
            {/* white container */}

            <div className=" z-60 relative -top-56 m-auto   flex  w-[1030x]  justify-between rounded-3xl bg-white  shadow-[0px_22px_70px_4px_rgba(0,0,0,0.2)]">
              <div className="flex w-11/12 flex-col gap-y-4 p-[18px] sm:w-[65%] sm:p-12 lg:w-1/2 ">
                <p className=" text-base font-medium text-BlueJay opacity-50 lg:text-[22px]">
                  Introducing Smart Report
                </p>

                <p className="font-jostSemibold text-2xl font-semibold text-BlueJay lg:text-4xl">
                  Innovative and Simplistic Report
                </p>

                <p className="w-11/12 text-base font-normal text-CadetGrey sm:w-full lg:text-xl">
                  Introducing the new and innovative &quot;smart lab
                  reports&quot; for comprehensive health check-ups. Gone are the
                  days of confusing lab reports and trying to decipher medical
                  jargon.
                  {/* Expert-designed lab reports for easy understanding. Visually appealing with customized graphics, tailored to your health needs, highlighting areas that require attention. Smart reports make tracking and understanding results easy.

                  Try our Smart Lab Reports for easy understanding and take the first step towards better health and happier you. */}
                </p>
                {/* Smart Report Link */}
                {/* <a href="https://zain-care.s3.ap-south-1.amazonaws.com/SAMPLE+SMART+HEALTH+PROFILE.pdf" target='_blank'> */}
                <CustomButton
                  width="w-fit sm:w-44 mt-2 relative z-[30]"
                  borderRadius="0.5rem"
                  variant="secondary-contained"
                  size="large"
                >
                  <a
                    href="https://zain-care.s3.ap-south-1.amazonaws.com/SAMPLE+SMART+HEALTH+PROFILE.pdf"
                    target="_blank"
                  >
                    View Smart Report
                  </a>
                </CustomButton>
              </div>
              <div></div>

              <img
                className="absolute  -bottom-60 right-0 w-[323px] sm:top-20  sm:w-[440px]   lg:right-10 lg:top-0 lg:w-[608px] "
                src="/images/images/mobile-with-report.png"
                alt=""
              />
            </div>
          </div>
        )}

        <div
          className={`${
            bepartner ? "pt-96  " : "pt-10"
          } flex justify-center md:pt-20 lg:hidden  lg:pt-20`}
        >
          <div className="flex flex-col items-center justify-center">
            <img src="/images/icons/zainlogo_withname.svg" alt="logoWithName" />
            {/* <p className="mt-[14px] text-xs text-[#909EB4]">By</p>
            <p className="font-satoshiMedium text-xs text-white">
              Zain AlShifa Home Healthcare Center LLC
            </p> */}
          </div>
        </div>

        <div className="m-auto max-w-[1440px] p-6 sm:p-12 ">
          {/* Menu for desktop */}
          <div className="hidden  grid-cols-3 items-start  gap-2 sm:grid lg:grid-cols-5">
            <div className="hidden flex-col  items-center justify-center lg:flex">
              <img
                src="/images/icons/zainlogo_withname.svg"
                alt="logoWithName"
              />
              {
                /* <p className="mt-[14px] text-xs text-[#909EB4]">By</p>
              <p className="font-satoshiMedium text-xs text-white">
                Zain AlShifa Home Healthcare Center LLC
              </p> */
              }
            </div>
            <div className=" flex flex-col gap-y-5">
              <p className="footer-heading">OUR SERVICES</p>
              <Link to="/packages" className="footer-content">
                TESTS & PACKAGES
              </Link>
            </div>
            <div className=" flex flex-col gap-y-5">
              <Link to="/about-us" className="footer-heading">
                ABOUT US
              </Link>
              <Link to="/partner-us" className="footer-content">
                PARTNER WITH US
              </Link>
              <Link to="/careers" className="footer-content">
                CAREERS
              </Link>
              <Link to="/terms-conditions" className="footer-content">
                TERMS AND CONDITIONS
              </Link>
              <Link to="/privacy-policy" className="footer-content">
                PRIVACY POLICY
              </Link>
              <Link to="/cookie-policy" className="footer-content">
                COOKIE POLICY
              </Link>
            </div>
            <div className=" flex flex-col gap-y-5">
              <p className="footer-heading">HELP</p>
              <Link to="/support" className="footer-content">
                CUSTOMER SUPPORT{" "}
              </Link>
              <Link to="/faq" className="footer-content">
                FAQ’S
              </Link>
              <Link to="/contact-us" className="footer-content">
                CONTACT US
              </Link>
            </div>

            <div className="hidden flex-col gap-y-5 lg:flex">
              <p className="footer-heading">FOLLOW US ON</p>
              <div className="grid grid-cols-5 gap-4   ">
                <a
                  href="https://instagram.com/zaincare_official"
                  target="__blank"
                >
                  <img
                    className="opacity-50"
                    src="/images/icons/instagram.svg"
                    alt="instagram_logo"
                  />
                </a>

                <a href="https://www.facebook.com/ZainCareUAE" target="__blank">
                  <img
                    className="opacity-50"
                    src="/images/icons/facebook.svg"
                    alt="facebook_logo"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="sm:hidden ">
            <FooterAccordion />
          </div>

          <br />
          <br />

          <div className=" flex flex-col items-center gap-y-5 lg:hidden">
            <div className="flex gap-4">
              <a
                href="https://instagram.com/zaincare_official"
                target="__blank"
              >
                <img
                  className="opacity-50"
                  src="/images/icons/instagram.svg"
                  alt="instagram_logo"
                />
              </a>

              <a href="https://www.facebook.com/ZainCareUAE" target="__blank">
                <img
                  className="opacity-50"
                  src="/images/icons/facebook.svg"
                  alt="facebook_logo"
                />
              </a>

            </div>
          </div>

          <br />
          <br />

          <CopyrightText />
        </div>

        {/* white box  */}
        {bepartner && (
          <div className="absolute -top-60  left-0 right-0 m-auto grid w-full  max-w-[347px] grid-cols-1 justify-between  gap-6 rounded-3xl  bg-white  p-6 sm:-top-40 sm:grid-cols-2  md:max-w-[704px]  lg:max-w-[1058px] lg:p-12 ">
            <div className="flex flex-col gap-y-5">
              <Input
                rows={1}
                width=""
                disabled={false}
                readOnly={false}
                handleChange={handleChange}
                value={params?.name}
                error={!!errors?.name}
                helperText={errors?.name}
                label="Name"
                name="name"
              />

              <Telephone
                onPhoneNumberChange={onPhoneNumberChange}
                params={params}
                errors={errors}
              />

              <p className="relative left-[15px] top-[-10px] mb-[-20px] font-satoshiMedium text-xs text-red-500">
                {errors?.phone}
              </p>
              <Input
                rows={1}
                width=""
                disabled={false}
                readOnly={false}
                handleChange={handleChange}
                value={params?.email}
                error={!!errors?.email}
                helperText={errors?.email}
                label="Email Id"
                name="email"
              />
            </div>
            <div className="flex flex-col gap-4">
              <TextArea
                placeholder="Special request if any in your inquiry"
                name="notes"
                rows={4}
                className="col-span-3"
                error={!!errors?.notes}
                value={params.notes}
                handleChange={handleChange}
                helperText={errors?.notes}
              />
              <CustomButton
                height={buttonHeight}
                borderRadius="0.5rem"
                variant="secondary-contained"
                size="large"
                onClick={handleSubmit}
              >
                Submit
              </CustomButton>
              <p className="py-2 text-center text-sm">{message}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
