import React, { useState } from "react";

// react-multi-carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const content = [
  {
    name: "Home Lab Test",
    image: "/images/cards/lab_test.svg",
    bg: "home_lab_test",
  },
  {
    name: "Home Nursing Care",
    image: "/images/cards/heart.svg",
    bg: "home_nursing_care",
  },
  {
    name: "IV Drip Theraphy",
    image: "/images/cards/ivdrip.svg",
    bg: "iv_drip",
  },
  {
    name: "Physiotherapy",
    image: "/images/cards/physio.svg",
    bg: "physio",
  },
  {
    name: "Rehabilitation",
    image: "/images/cards/doctor.svg",
    bg: "rehab",
  },
];

const RightArrow = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 3.4641C33.7128 1.32051 38.2872 1.32051 42 3.4641L61.1769 14.5359C64.8897 16.6795 67.1769 20.641 67.1769 24.9282V47.0718C67.1769 51.359 64.8897 55.3205 61.1769 57.4641L42 68.5359C38.2872 70.6795 33.7128 70.6795 30 68.5359L10.8231 57.4641C7.11027 55.3205 4.82309 51.359 4.82309 47.0718V24.9282C4.82309 20.641 7.11027 16.6795 10.8231 14.5359L30 3.4641Z"
        fill="#33B0AA"
      />
      <path
        d="M33.57 29.9297L27.5 35.9997L33.57 42.0697"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5 36H27.67"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Card = ({ data, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${data.bg} relative m-auto mt-2 flex cursor-pointer flex-col items-center justify-between rounded-xl p-5 shadow-md md:my-10 lg:my-10`}
    >
      <img
        src={data.image}
        alt={data.name}
        className="absolute top-[30px] h-[212px] w-[175px] object-contain transition-all hover:scale-[1.1]"
      />
      <p className="font-jostMedium absolute bottom-[20px] text-sm text-white">
        {data.name}
      </p>
    </div>
  );
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      className={active ? "mx-2" : "mx-2 opacity-60"}
      onClick={() => onClick()}
    >
      <div
        className={`hidden h-[10px] w-[70px] rounded md:block ${
          active ? "bg-blueShadow" : "bg-[#D5E4E9]"
        } cursor-pointer`}
      ></div>

      <div
        className={`block rounded-full md:hidden ${
          active
            ? " h-[8px] w-[21px] bg-[#D5E4E9]"
            : "h-[8px]  w-[8px] bg-[#D5E4E9]"
        } cursor-pointer transition-all duration-100 `}
      ></div>
    </li>
  );
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;

  return (
    <div className="hidden md:block lg:block">
      <button
        className="absolute -left-20 md:top-[40%] lg:-left-20 lg:top-[40%]"
        onClick={() => previous()}
      >
        <RightArrow />
      </button>
      <button
        className="absolute -right-20 rotate-180 md:top-[40%] lg:-right-20  lg:top-[40%]"
        onClick={() => next()}
      >
        <RightArrow />
      </button>
    </div>
  );
};

const Cares = ({ handleContactFormOpen, setServiceType }) => {
  const [currentSlide, setCurrent] = useState();

  const navigate = useNavigate();
  return (
    <div className="relative m-auto w-full md:w-[600px] lg:w-[1090px]">
      <Carousel
        arrows={false}
        draggable={false}
        infinite
        className=""
        itemClass=""
        dotListClass=""
        additionalTransfrom={0}
        customDot={<CustomDot />}
        centerMode={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
        containerClass="container-with-dots"
        focusOnSelect={false}
        keyBoardControl={false}
        minimumTouchDrag={80}
        pauseOnHover={false}
        renderArrowsWhenDisabled={false}
        renderDotsOutside={true}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 3,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        shouldResetAutoplay={false}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable={true}
        ref={(el) => {
          if (el) {
            var slidesToShow = el.state.slidesToShow;
            var currentSlide = el.state.currentSlide;

            setCurrent(currentSlide - slidesToShow);
          }
        }}
      >
        {React.Children.toArray(
          content.map((item) => (
            <Card
              onClick={() => {
                if (item.name === "Home Lab Test") {
                  navigate("/packages");
                } else {
                  setServiceType(item?.name);
                  handleContactFormOpen();
                }
              }}
              data={item}
            />
          ))
        )}
      </Carousel>

      <div className="mt-3 flex h-6 w-full items-center justify-center gap-2 md:hidden "></div>
    </div>
  );
};

export default Cares;
